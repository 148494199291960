import { skipToken } from '@reduxjs/toolkit/dist/query';
import { List, Space } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import RecyclingBinCard from 'components/atoms/RecyclingBinCard';
import { RecycleControls } from 'components/organisms/RecycleControls';
import { DeploymentRequestTypes } from 'models/DeploymentRequestTypes';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetDeploymentRequestConfigurationTypesQuery } from 'redux/services/drNobelPrice/drNobelPriceConfigTypes';
import { ReduxState } from 'redux/store';

export const TypesRecycleBinPage = (): JSX.Element => {
  const { divisionId } = useParams();
  const { deploymentRequestTypesParams } = useSelector((state: ReduxState) => state);

  const { data, isLoading, isFetching } = useGetDeploymentRequestConfigurationTypesQuery(
    divisionId ? { divisionId, params: { ...deploymentRequestTypesParams, returnDeletedDataOnly: true } } : skipToken
  );

  if (isLoading || isFetching) return <BlockLoader direction="loader loader--slideUp" />;

  return (
    <Space direction="vertical">
      <RecycleControls />
      <List dataSource={data?.data} grid={{ gutter: [5, 5] as any, column: 4 }} renderItem={(item: DeploymentRequestTypes): React.ReactNode => <RecyclingBinCard type={item} />} />
    </Space>
  );
};
