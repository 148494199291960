import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Form, Select, SelectProps } from 'antd';
import { useField } from 'formik';
import { RuleFilter } from 'models/DeploymentRequestConfig';
import { useParams } from 'react-router-dom';
import { useGetDeploymentRequestConfigurationQuery } from 'redux/services/drNobelPrice/drNobelPriceConfig';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps {
  fieldName: string;
  label?: string;
}

export const DispositionRuleSelectInput = ({ fieldName, label, ...rest }: Props): JSX.Element => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<string>(fieldName);

  const { divisionId } = useParams();

  const handleChange = (value: string): void => {
    setValue(value);
  };

  const { data, isLoading, isFetching } = useGetDeploymentRequestConfigurationQuery(divisionId ? divisionId : skipToken);

  const options = data?.allowedDeploymentRequestTypes.map((option: any) => ({
    label: option === 'AllProjects' ? RuleFilter.AllProjects : option === 'SpecificProjects' ? RuleFilter.SpecificProjects : option,
    value: option.replace(/\s/g, '')
  }));

  return (
    <Form.Item
      labelAlign="left"
      validateStatus={touched && error ? 'error' : 'success'}
      label={label ? <FormLabel label={label} /> : undefined}
      help={touched && error ? error : undefined}
      style={{ marginBottom: 0 }}
      labelCol={{ style: { padding: 0 } }}>
      <Select
        allowClear
        showSearch
        loading={isLoading || isFetching}
        onChange={handleChange}
        value={value}
        options={options}
        {...rest}
        onFocus={(): void => setTouched(false)}
        onBlur={(): void => setTouched(true)}
        style={{ borderRadius: 5 }}
      />
    </Form.Item>
  );
};
