import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Card, Col, Dropdown, Form, List, Menu, Modal, Row, Space, Switch, Tooltip, Typography } from 'antd';
import { CurrencyInput } from 'components/UI/FormItems/CurrencyInput';
import { SelectInput } from 'components/UI/FormItems/SelectInput';
import { SwitchInputCard } from 'components/UI/FormItems/SwitchInputCard';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { Approvals, UpsertDeploymentRequestConfigurationPayload } from 'models/DeploymentRequestConfig';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React, { useState } from 'react';

interface Props {
  approval: Approvals;
  index: number;
}

const styles: InlineStylesModel = {
  smallGrouping: {
    color: 'grey',
    fontSize: '8',
    background: '#8383831a',
    padding: '6px 12px',
    borderRadius: 100,
    marginRight: 4
  }
};

const testIds = ['user1', 'user2', 'user3', 'user4'];

export const ApprovalListItem: React.FC<Props> = ({ approval, index }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { values, setValues } = useFormikContext<UpsertDeploymentRequestConfigurationPayload>();

  const [{ value }, , { setValue: setOrderApproval }] = useField<Approvals>(`orderApprovals[${index}]`);

  const editApprovalsFormik = useFormik<Approvals>({
    enableReinitialize: true,
    initialValues: {
      isActive: value.isActive,
      approverUserIds: value.approverUserIds,
      orderCostThreshold: value.orderCostThreshold
    },
    onSubmit: (values) => {
      setOrderApproval(values);
      setIsOpen(false);
      editApprovalsFormik.resetForm();
    }
  });

  const handleChange = (checked: boolean): void => {
    if (!values.orderApprovals) return;

    const updatedApprovals = values.orderApprovals.map((item) => (item.id === approval.id && item.orderCostThreshold === approval.orderCostThreshold ? { ...item, isActive: checked } : { ...item }));

    setValues((prev) => ({ ...prev, orderApprovals: updatedApprovals }));
  };

  const handleCancel = (): void => {
    setIsOpen(false);
    editApprovalsFormik.resetForm();
  };

  const handleOpenModal = (): void => {
    setIsOpen(true);
  };
  const handleDelete = (): void => {
    if (!values.orderApprovals) return;
    const filteredApprovals = values.orderApprovals.filter((item) => approval !== item);

    setValues((prev) => ({ ...prev, orderApprovals: filteredApprovals }));
    setIsOpen(false);
  };

  const handleEdit = (): void => {
    editApprovalsFormik.submitForm();
  };

  const menu = (
    <Menu disabledOverflow={true}>
      <Menu.Item onClick={handleOpenModal}>Edit</Menu.Item>
      <Menu.Item danger onClick={handleDelete}>
        Delete
      </Menu.Item>
    </Menu>
  );

  /* ******************** Renderer ******************** */
  return (
    <FormikProvider value={editApprovalsFormik}>
      <Col style={{ width: '100%' }}>
        <Card
          style={{ width: '100%', marginBottom: 5 }}
          headStyle={{ padding: '0px 18px' }}
          title={
            <Row justify="space-between" align="middle" style={{ margin: '0px 6px' }}>
              <Space>
                <Typography.Text style={{ fontWeight: 500, fontSize: 18 }}>Cost Threshold: ${approval.orderCostThreshold}</Typography.Text>
              </Space>
              <Space>
                <Tooltip title="Is Order Approval Active">
                  <Switch checked={approval.isActive} onChange={(checked): void => handleChange(checked)} />
                </Tooltip>
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button icon={<EllipsisOutlined />} style={{ border: 'none', background: 'rgba(0,0,0,.1)', borderRadius: '50%' }} />
                </Dropdown>
              </Space>
            </Row>
          }>
          <Row justify="space-between" align="bottom">
            <Space direction="vertical">
              <Col span={24} style={{ marginBottom: 5, marginLeft: !approval.approverUserIds.length ? 0 : 4 }}>
                <Typography.Text style={{ fontWeight: 400, fontSize: 15 }}>Approved Users: </Typography.Text>
              </Col>
              <Col span={24}>
                <Row justify="space-between">
                  {approval.approverUserIds.length ? (
                    <List
                      dataSource={approval.approverUserIds}
                      renderItem={(item): React.ReactNode => (
                        <>
                          <Typography.Text style={styles.smallGrouping}>{item ?? 'N/A'}</Typography.Text>
                        </>
                      )}
                    />
                  ) : (
                    <Typography.Text>N/A</Typography.Text>
                  )}
                </Row>
              </Col>
            </Space>
          </Row>
        </Card>
        <Modal onOk={handleEdit} onCancel={handleCancel} open={isOpen} title="Edit Order Approval">
          <Form layout="vertical">
            <Space size={15} direction="vertical">
              <CurrencyInput label="Order Cost Threshold" fieldName="orderCostThreshold" />
              <SelectInput mode="multiple" style={{ width: 150 }} selectOptions={testIds} fieldName="approverUserIds" label="Approved Users" />
              <SwitchInputCard fieldName="isActive" label="Is Active" />
            </Space>
          </Form>
        </Modal>
      </Col>
    </FormikProvider>
  );
};
