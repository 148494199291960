import { Form, Select, SelectProps, Space } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { useField } from 'formik';
import { useEffect } from 'react';
import { useGetWorldsQuery } from 'redux/services/chuckieSue/worldsApi';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps {
  countryFieldName: string;
  stateFieldName: string;
  countryLabel?: string;
  stateLabel?: string;
}

export const CountriesSelectInput = ({ countryFieldName, stateFieldName, countryLabel, stateLabel, ...rest }: Props): JSX.Element => {
  const [{ value: selectedCountry }, { touched, error }, { setValue, setTouched }] = useField<string>(countryFieldName);
  const [{ value: selectedState }, { touched: cityTouched, error: cityError }, { setValue: setCityValue, setTouched: setCityTouched }] = useField<string>(stateFieldName);

  const { data, isLoading, isFetching } = useGetWorldsQuery();

  const countryNames = data?.data[0].countries.map((country) => ({ label: country.shortName, value: country.alpha2Code }));

  const {
    data: states,
    isLoading: isStatesLoading,
    isFetching: isStatesFetching
  } = useGetWorldsQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({ data: data?.data[0].countries.find((country) => country.alpha2Code === selectedCountry || country.shortName === selectedCountry), ...rest })
  });

  const stateOptions: DefaultOptionType[] | undefined = states?.subdivisions.map((state) => ({ label: state.name, value: state.name }));

  const handleChange = (value: string): void => {
    setValue(value);
  };
  const handleCityChange = (value: string): void => {
    setCityValue(value);
  };

  const handleCountryClear = (): void => {
    setValue('');
    setCityValue('');
  };
  const handleStateClear = (): void => {
    setCityValue('');
  };

  useEffect(() => {
    setValue('US');
  }, []);

  if (isLoading || isStatesLoading) return <BlockLoader small direction="loader loader--slideUp" />;

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={15}>
      <Form.Item
        labelAlign="left"
        validateStatus={touched && error ? 'error' : 'success'}
        label={countryLabel ? <FormLabel label={countryLabel} /> : undefined}
        help={touched && error ? error : undefined}
        style={{ marginBottom: 0 }}
        labelCol={{ style: { padding: 0 } }}>
        <Select
          onChange={handleChange}
          allowClear
          showSearch
          filterOption={(input, option) => option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onClear={handleCountryClear}
          value={selectedCountry}
          loading={isLoading || isFetching}
          options={countryNames}
          onFocus={(): void => setTouched(false)}
          onBlur={(): void => setTouched(true)}
          style={{ borderRadius: 5 }}
          {...rest}
        />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        validateStatus={cityTouched && cityError ? 'error' : 'success'}
        label={stateLabel ? <FormLabel label={stateLabel} /> : undefined}
        help={cityTouched && cityError ? cityError : undefined}
        style={{ marginBottom: 0 }}
        labelCol={{ style: { padding: 0 } }}>
        <Select
          onChange={handleCityChange}
          showSearch
          disabled={!selectedCountry}
          value={selectedState}
          loading={isStatesLoading || isStatesFetching}
          options={stateOptions}
          onFocus={(): void => setCityTouched(false)}
          onBlur={(): void => setCityTouched(true)}
          style={{ borderRadius: 5 }}
          {...rest}
        />
      </Form.Item>
    </Space>
  );
};
