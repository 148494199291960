import { DeleteOutlined, EditOutlined, HolderOutlined, WarningFilled } from '@ant-design/icons';
import { Button, Card, Col, Form, message, Modal, Row, Space, Table, Tooltip, Typography } from 'antd';
import { AddRuleMessage } from 'components/atoms/addRule/AddRuleMessage';
import { ConditionsSelectInput } from 'components/UI/FormItems/ConditionsSelectInput.tsx';
import { DispositionSelectInput } from 'components/UI/FormItems/DispositionSelectInput.';
import { OwnersSelectInput } from 'components/UI/FormItems/OwnersSelectInput';
import { SwitchInputCard } from 'components/UI/FormItems/SwitchInputCard';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import update from 'immutability-helper';
import _ from 'lodash';
import { CreateDeploymentRequestType, DimensionFulfillmentRules, fulfillmentRulesPayload } from 'models/DeploymentRequestTypes';
import { useCallback, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ReactComponent as CloneIcon } from '../../common/assets/clone.svg';

interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

export const DimensionFulfillmentTable: React.FC = (): JSX.Element => {
  const [{ value }, , { setValue }] = useField<DimensionFulfillmentRules[]>('dimensionFulfillmentRules');
  const [index, setIndex] = useState<number>(0);
  const [{ value: record }, , { setValue: setRecord }] = useField<DimensionFulfillmentRules>(`dimensionFulfillmentRules[${index}]`);
  const { values: dimensionFulfillmentRulesValues, setValues } = useFormikContext<CreateDeploymentRequestType>();

  const [isOpen, setIsOpen] = useState<boolean | undefined>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean | undefined>(false);
  const [isClone, setIsClone] = useState<boolean>(false);
  const [modal, contextHolder] = Modal.useModal();

  const type = 'DraggableBodyRow';

  const commonStockFormik = useFormik<DimensionFulfillmentRules>({
    enableReinitialize: true,
    validationSchema: fulfillmentRulesPayload,
    initialValues: {
      conditionId: '',
      dispositionId: '',
      isDefault: false,
      isCommonStock: false,
      ownerId: '',
      priority: value?.length ? value.length + 1 : 1
    },
    onSubmit: (values) => {
      if (
        dimensionFulfillmentRulesValues.dimensionFulfillmentRules?.find(
          (rule) =>
            rule.conditionId === values.conditionId &&
            rule.dispositionId === values.dispositionId &&
            rule.isCommonStock === values.isCommonStock &&
            rule.ownerId === values.ownerId &&
            rule.isDefault === values.isDefault
        )
      ) {
        message.error('Rule already exists, no duplicates allowed');

        return;
      }
      if (dimensionFulfillmentRulesValues.dimensionFulfillmentRules?.find((rule) => rule.isCommonStock && rule.isDefault && values.isCommonStock && values.isDefault)) {
        message.error('You can only have 1 default common stock rule');

        return;
      }
      if (dimensionFulfillmentRulesValues.dimensionFulfillmentRules?.find((rule) => !rule.isCommonStock && rule.isDefault && !values.isCommonStock && values.isDefault)) {
        message.error('You can only have 1 default non common stock rule');

        return;
      }
      setValue([...value, values]);
      setIsOpen(false);
      commonStockFormik.resetForm();
    }
  });
  const editCommonStockFormik = useFormik<DimensionFulfillmentRules>({
    enableReinitialize: true,
    validationSchema: fulfillmentRulesPayload,
    initialValues: {
      conditionId: record ? record.conditionId : '',
      dispositionId: record ? record.dispositionId : '',
      isDefault: record ? record.isDefault : false,
      isCommonStock: record ? record.isCommonStock : false,
      ownerId: record ? record.ownerId : '',
      priority: record ? record.priority : 0
    },
    onSubmit: (values) => {
      if (
        dimensionFulfillmentRulesValues.dimensionFulfillmentRules?.find(
          (rule) =>
            rule.conditionId === values.conditionId &&
            rule.dispositionId === values.dispositionId &&
            rule.isCommonStock === values.isCommonStock &&
            rule.ownerId === values.ownerId &&
            rule.isDefault === values.isDefault
        )
      ) {
        message.error('Rule already exists, no duplicates allowed');

        return;
      }
      if (dimensionFulfillmentRulesValues.dimensionFulfillmentRules?.find((rule) => rule.isCommonStock && rule.isDefault && values.isCommonStock && values.isDefault)) {
        message.error('You can only have 1 default common stock rule');

        return;
      }
      if (dimensionFulfillmentRulesValues.dimensionFulfillmentRules?.find((rule) => !rule.isCommonStock && rule.isDefault && !values.isCommonStock && values.isDefault)) {
        message.error('You can only have 1 default non common stock rule');

        return;
      }
      if (isClone) {
        setValues((prev) => ({
          ...prev,
          dimensionFulfillmentRules: [...value, values].map((row, i) => ({
            ...row,
            priority: i + 1
          }))
        }));
        setIsEditOpen(false);
        editCommonStockFormik.resetForm();
        setIsClone(false);

        return;
      }
      setRecord(values);
      setIsEditOpen(false);
      editCommonStockFormik.resetForm();
    }
  });

  const handleSubmit = (): void => {
    commonStockFormik.submitForm();
  };

  const handleCancel = (): void => {
    setIsOpen(false);
    setIsEditOpen(false);
    commonStockFormik.resetForm();
    editCommonStockFormik.resetForm();
    setIsClone(false);
  };

  const handleDeleteRow = (record: DimensionFulfillmentRules): void => {
    if (!dimensionFulfillmentRulesValues.dimensionFulfillmentRules) return;

    setValues((prev) => ({
      ...prev,
      dimensionFulfillmentRules: dimensionFulfillmentRulesValues.dimensionFulfillmentRules
        ? dimensionFulfillmentRulesValues.dimensionFulfillmentRules.filter((item) => item !== record).map((item, i) => ({ ...item, priority: i + 1 }))
        : undefined
    }));
  };

  const handleEditRow = (_: DimensionFulfillmentRules, index: number): void => {
    setIndex(index);
    setIsEditOpen(true);
  };
  const handleClone = (_: DimensionFulfillmentRules, index: number): void => {
    setIndex(index);
    setIsClone(true);
    setIsEditOpen(true);
  };

  const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }: DraggableBodyRowProps): JSX.Element => {
    const ref = useRef<HTMLTableRowElement>(null);

    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};

        if (dragIndex === index) {
          return {};
        }

        return {
          isOver: monitor.isOver(),
          dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
        };
      },
      drop: (item: { index: number }) => {
        moveRow(item.index, index);
      }
    });
    const [, drag] = useDrag({
      type,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    });

    drop(drag(ref));

    return <tr ref={ref} className={`${className}${isOver ? dropClassName : ''}`} style={{ cursor: 'move', ...style }} {...restProps} />;
  };

  const cols = [
    {
      dataIndex: 'sort',
      width: 30,
      render: () => <HolderOutlined style={{ cursor: 'grab' }} />
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority'
    },
    {
      title: 'Owner',
      dataIndex: 'ownerId',
      key: 'ownerId'
    },
    {
      title: 'Condition',
      dataIndex: 'conditionId',
      key: 'conditionId'
    },
    {
      title: 'Disposition',
      dataIndex: 'dispositionId',
      key: 'dispositionId'
    },
    {
      title: 'Is Default',
      dataIndex: 'isDefault',
      key: 'isDefault',
      render: (_: string, record: DimensionFulfillmentRules) => <Typography.Text>{record.isDefault ? 'Yes' : 'No'}</Typography.Text>
    },
    {
      title: 'Is Common Stock',
      dataIndex: 'isCommonStock',
      key: 'isCommonStock',
      render: (_: string, record: DimensionFulfillmentRules) => <Typography.Text>{record.isCommonStock ? 'Yes' : 'No'}</Typography.Text>
    },
    {
      title: 'Actions',
      width: 125,
      align: 'center' as any,
      render: (_: string, record: DimensionFulfillmentRules, i: number) => (
        <Row justify="center" gutter={[1, 1]}>
          <Col>
            <Tooltip title="Clone Rule">
              <Button size="small" onClick={(): void => handleClone(record, i)} icon={<CloneIcon style={{ height: 25, width: 20 }} />} />
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title="Edit Rule">
              <Button size="small" onClick={(): void => handleEditRow(record, i)} icon={<EditOutlined />} />
            </Tooltip>
          </Col>
          <Col>
            {contextHolder}
            <Tooltip title="Delete Rule">
              <Button
                size="small"
                onClick={() =>
                  modal.confirm({
                    title: 'IF YOU CONTINUE, THE CHANGES WILL NOT GO INTO EFFECT UNTIL YOU CLICK SAVE.',
                    icon: <WarningFilled style={{ color: 'red' }} />,
                    okButtonProps: { style: { background: 'red', border: 'none' } },
                    okText: 'Delete',
                    onOk: () => handleDeleteRow(record),
                    prefixCls: 'ant-modal'
                  })
                }
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Col>
        </Row>
      )
    }
  ];

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (!dimensionFulfillmentRulesValues.dimensionFulfillmentRules) return;
      const dragRow = value[dragIndex];

      setValue(
        update(dimensionFulfillmentRulesValues.dimensionFulfillmentRules, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        }).map((d, i) => ({ ...d, priority: i + 1, key: i }))
      );
    },
    [dimensionFulfillmentRulesValues, value]
  );

  const components = {
    body: {
      row: DraggableBodyRow
    }
  };

  return (
    <Card
      title={
        <Row align="middle">
          <Typography.Title level={4} style={{ fontWeight: 400, padding: 0, margin: 0, marginRight: 5 }}>
            Dimension Fullfilment Rules
          </Typography.Title>
        </Row>
      }
      headStyle={{ background: '#18a79984' }}
      extra={<Button onClick={(): void => setIsOpen(true)}>Add</Button>}>
      <DndProvider backend={HTML5Backend}>
        <FormikProvider value={commonStockFormik}>
          <Modal width={400} onCancel={handleCancel} onOk={handleSubmit} title="Add Dimension Fullfilment Rule" open={isOpen}>
            <Form layout="vertical">
              <Space direction="vertical" size={15} style={{ width: '100%' }}>
                <OwnersSelectInput fieldName="ownerId" label="Owner" />
                <ConditionsSelectInput fieldName="conditionId" label="Condition" />
                <DispositionSelectInput fieldName="dispositionId" label="Disposition" />
                <SwitchInputCard fieldName="isDefault" label="Is Default" />
                <SwitchInputCard fieldName="isCommonStock" label="Is Common Stock" />
              </Space>
            </Form>
          </Modal>
        </FormikProvider>
        <FormikProvider value={editCommonStockFormik}>
          <Modal
            okButtonProps={{ disabled: _.isEqual(editCommonStockFormik.initialValues, editCommonStockFormik.values) }}
            width={400}
            onCancel={handleCancel}
            onOk={(): Promise<void> => editCommonStockFormik.submitForm()}
            title={!isClone ? 'Edit Dimension Fullfilment Rule' : 'Add Dimension Fullfilment Rule'}
            open={isEditOpen}>
            <Form layout="vertical">
              <Space direction="vertical" size={15} style={{ width: '100%' }}>
                <OwnersSelectInput fieldName="ownerId" label="Owner" />
                <ConditionsSelectInput fieldName="conditionId" label="Condition" />
                <DispositionSelectInput fieldName="dispositionId" label="Disposition" />
                <SwitchInputCard fieldName="isDefault" label="Is Default" />
                <SwitchInputCard fieldName="isCommonStock" label="Is Common Stock" />
              </Space>
            </Form>
          </Modal>
        </FormikProvider>
        <Table
          locale={{ emptyText: <AddRuleMessage /> }}
          columns={cols}
          pagination={false}
          dataSource={dimensionFulfillmentRulesValues.dimensionFulfillmentRules}
          components={components}
          rowKey={(record): string => `${record.ownerId} - ${record.dispositionId} - ${record.conditionId} - ${record.priority}`}
          onRow={(_, index) => {
            const attr = {
              index,
              moveRow
            };

            return attr as React.HTMLAttributes<any>;
          }}
        />
      </DndProvider>
    </Card>
  );
};
