import { skipToken } from '@reduxjs/toolkit/dist/query';
import { message, Space, Spin } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { EditPageControls } from 'components/molecules/EditPageControls';
import { EditPageCards } from 'components/organisms/EditPageCards';
import { FormikProvider, useFormik } from 'formik';
import { upsertConfigurationPayload, UpsertDeploymentRequestConfigurationPayload } from 'models/DeploymentRequestConfig';
import { useParams } from 'react-router-dom';
import { useGetDeploymentRequestConfigurationQuery, useUpdateDeploymentRequestConfigurationMutation } from 'redux/services/drNobelPrice/drNobelPriceConfig';

export const EditPage = (): JSX.Element => {
  const { divisionId } = useParams();

  const { data: test, isLoading, isFetching } = useGetDeploymentRequestConfigurationQuery(divisionId ?? skipToken);

  const [triggerUpdate, { isLoading: isUpdating }] = useUpdateDeploymentRequestConfigurationMutation();

  const formik = useFormik<UpsertDeploymentRequestConfigurationPayload>({
    enableReinitialize: true,
    validationSchema: upsertConfigurationPayload,
    initialValues: {
      allowedDeploymentRequestTypes: test?.allowedDeploymentRequestTypes ?? undefined,
      orderApprovals: test?.orderApprovals ?? undefined,
      addressDetails: {
        installAddressTypes: test?.addressDetails.installAddressTypes ?? [],
        isManualAddressAllowedToBeAddedToPostOffice: test?.addressDetails?.isManualAddressAllowedToBeAddedToPostOffice ?? false,
        istManualAddressEntryAllowed: test?.addressDetails?.istManualAddressEntryAllowed ?? false,
        shippingAddressTypes: test?.addressDetails.shippingAddressTypes ?? [],
        displayAddressesByDefault: test?.addressDetails.displayAddressesByDefault ?? false
      },
      reference1Label: test?.reference1Label ?? '',
      reference2Label: test?.reference2Label ?? '',
      areProjectsEnabled: test?.areProjectsEnabled ?? false,
      areProjectsRequired: test?.areProjectsRequired ?? false,
      isStandardCostDisplayed: test?.isStandardCostDisplayed ?? false,
      notifications: {
        shipmentNotifications: {
          emailAddresses: test?.notifications.shipmentNotifications.emailAddresses ?? [],
          areShipmentNotificationsEnabled: test?.notifications.shipmentNotifications.areShipmentNotificationsEnabled ?? false
        },
        releaseNotifications: {
          emailAddresses: test?.notifications.releaseNotifications.emailAddresses ?? [],
          areReleasedNotificationsEnabled: test?.notifications.releaseNotifications.areReleaseNotificationsEnabled ?? false
        }
      },
      shipmentDetails: {
        automaticallySetShippingContactInfoToUsersContactInfo: test?.shipmentDetails.automaticallySetShippingContactInfoToUsersContactInfo ?? false,
        isExpeditedShipmentAllowed: test?.shipmentDetails.isExpeditedShipmentAllowed ?? false,
        isExpeditedShipmentApprovalRequired: test?.shipmentDetails.isExpeditedShipmentApprovalRequired ?? false,
        isShipCompleteRequired: test?.shipmentDetails.isShipCompleteRequired ?? false
      }
    },

    onSubmit: async (values) => {
      try {
        if (!divisionId) {
          message.error('Division cannot be found');

          return;
        }
        const updatedDated = await triggerUpdate({ divisionId, payload: { ...test, ...values } }).unwrap();

        message.success(`Successfully updated ${updatedDated.division.name} - ${updatedDated.division.erpId} configuration`);
      } catch (err) {
        console.log(err);
        message.error((err as { data: { errorMessage: string } }).data.errorMessage ?? 'Could not be updated at this time');
      }
    }
  });

  if (isLoading || isFetching) return <BlockLoader direction="loader loader--slideUp" />;

  return (
    <FormikProvider value={formik}>
      <Space size={6} direction="vertical">
        <Spin spinning={isUpdating} indicator={<BlockLoader direction="loader loader--slideUp" />}>
          <EditPageControls />
          <EditPageCards config={test} />
        </Spin>
      </Space>
    </FormikProvider>
  );
};
