import { Card, Col, Space, Typography } from 'antd';
import { SwitchInputCard } from 'components/UI/FormItems/SwitchInputCard';
import { useFormikContext } from 'formik';
import { DeploymentRequestConfiguration } from 'models/DeploymentRequestConfig';
import React, { useEffect } from 'react';

export const ShipmentsCard: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<DeploymentRequestConfiguration>();

  useEffect(() => {
    if (!values.shipmentDetails.isExpeditedShipmentAllowed) {
      setFieldValue('shipmentDetails.isExpeditedShipmentApprovalRequired', false);
    }
  }, [values]);

  /* ******************** Renderer ******************** */
  return (
    <Col span={6} xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
      <Card
        title={
          <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
            Shipments
          </Typography.Title>
        }
        headStyle={{ background: '#18a79984' }}
        bodyStyle={{ padding: 20 }}
        style={{ height: '100%' }}>
        <Space direction="vertical" style={{ width: '100%' }} size={20}>
          <SwitchInputCard fieldName="shipmentDetails.isShipCompleteRequired" label="Is Ship Complete Required" />
          <SwitchInputCard fieldName="shipmentDetails.isExpeditedShipmentAllowed" label="Allow Expedited Shipping" />
          <SwitchInputCard
            color={!values.shipmentDetails.isExpeditedShipmentAllowed ? 'lightgray' : ''}
            disabled={!values.shipmentDetails.isExpeditedShipmentAllowed}
            fieldName="shipmentDetails.isExpeditedShipmentApprovalRequired"
            label="Expedited Shipping Requires Approval"
          />
          <SwitchInputCard fieldName="shipmentDetails.automaticallySetShippingContactInfoToUsersContactInfo" label="Set Shipping Contact Info to User's" />
        </Space>
      </Card>
    </Col>
  );
};
