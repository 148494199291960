import * as yup from 'yup';
export interface EditDeploymentRequestConfig {
  isReleaseOrderRequired: boolean;
  isStandardCostDisplayed: boolean;
  installAddressTypes: string[];
  shippingAddressTypes: string[];
  isManualAddressEntryAllowed: boolean;
  isManualAddressAllowedToBeAddedToPostOffice: boolean;
  isPartialShipmentAllowed: boolean;
  automaticallySetShippingContactInfoToUsersContactInfo: boolean;
  isExpeditedShipmentAllowed: boolean;
  isExpeditedShipmentApprovalRequired: boolean;
  areProjectsEnabled: boolean;
  areProjectsRequired: boolean;
  areReleaseNotificationsEnabled: boolean;
  areShipmentNotificationsEnabled: boolean;
}

export interface Approvals {
  isActive?: boolean;
  orderCostThreshold?: number;
  approverUserIds: string[];
  id?: string;
}

export interface Shipments {
  automaticallySetShippingContactInfoToUsersContactInfo: boolean;
  isExpeditedShipmentAllowed: boolean;
  isExpeditedShipmentApprovalRequired: boolean;
  isShipCompleteRequired: boolean;
}

export interface DeploymentRequestConfiguration {
  addressDetails: {
    installAddressTypes: string[];
    isManualAddressAllowedToBeAddedToPostOffice: boolean;
    istManualAddressEntryAllowed: boolean;
    displayAddressesByDefault: boolean;
    shippingAddressTypes: string[];
  };
  allowedDeploymentRequestTypes: string[];
  areProjectsEnabled: boolean;
  areProjectsRequired: boolean;
  auditHistory?: string[];
  createdByFullName?: string;
  createdByUserId?: string;
  createdDateTime?: string;
  reference1Label: string;
  reference2Label: string;
  division: {
    business: {
      dataAreaId: string;
      erpId: string;
      id: string;
      name: string;
    };
    erpId: string;
    id: string;
    name: string;
  };
  documentType: string;
  id: string;
  isStandardCostDisplayed: boolean;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  notifications: {
    releaseNotifications: {
      areReleaseNotificationsEnabled: boolean;
      emailAddresses: string[];
    };
    shipmentNotifications: {
      areShipmentNotificationsEnabled: boolean;
      emailAddresses: string[];
    };
  };
  orderApprovals: Approvals[];
  partitionKey: string;
  partitionKeyDescription: string;
  shipmentDetails: {
    automaticallySetShippingContactInfoToUsersContactInfo: boolean;
    isExpeditedShipmentAllowed: boolean;
    isExpeditedShipmentApprovalRequired: boolean;
    isShipCompleteRequired: boolean;
  };
}

export interface UpsertDeploymentRequestConfigurationPayload {
  orderApprovals?: Approvals[];
  allowedDeploymentRequestTypes?: string[];
  addressDetails?: {
    installAddressTypes: string[];
    isManualAddressAllowedToBeAddedToPostOffice: boolean;
    istManualAddressEntryAllowed: boolean;
    displayAddressesByDefault: boolean;
    shippingAddressTypes: string[];
  };
  areProjectsEnabled: boolean;
  areProjectsRequired: boolean;
  isStandardCostDisplayed: boolean;
  reference1Label: string;
  reference2Label: string;
  notifications: {
    releaseNotifications: {
      areReleasedNotificationsEnabled: boolean;
      emailAddresses: string[];
    };
    shipmentNotifications: {
      areShipmentNotificationsEnabled: boolean;
      emailAddresses: string[];
    };
  };
  shipmentDetails?: {
    automaticallySetShippingContactInfoToUsersContactInfo: boolean;
    isExpeditedShipmentAllowed: boolean;
    isExpeditedShipmentApprovalRequired: boolean;
    isShipCompleteRequired: boolean;
  };
}

export interface RequiredConfigFields {
  allowedDeploymentRequestTypes: string[];
}

export enum RuleFilter {
  Disposition = 'Disposition',
  AllProjects = 'All Projects',
  SpecificProjects = 'Specific Projects'
}

export const approvalsPayload: yup.SchemaOf<Approvals> = yup.object({
  isActive: yup.boolean().label('Is Active').required(),
  orderCostThreshold: yup.number().label('Order Cost Threshold').required(),
  approverUserIds: yup.array().label('Approved User Ids').min(1).required(),
  id: yup.string()
});
export const upsertConfigurationPayload: yup.SchemaOf<RequiredConfigFields> = yup.object({
  allowedDeploymentRequestTypes: yup.array().min(1).required('Allowed deployment request types must have at least one option selected')
});
export const notifications: yup.SchemaOf<RequiredConfigFields> = yup.object({
  allowedDeploymentRequestTypes: yup.array().min(1).required('Allowed deployment request types must have at least one option selected')
});
