import { DeleteOutlined, EditOutlined, HolderOutlined, WarningFilled } from '@ant-design/icons';
import { Button, Card, Col, Form, message, Modal, Row, Space, Table, Tooltip, Typography } from 'antd';
import { AddRuleMessage } from 'components/atoms/addRule/AddRuleMessage';
import { DeliveryModeTypeSelect } from 'components/UI/FormItems/DeliveryModeTypeSelect';
import { TextInput } from 'components/UI/FormItems/TextInput';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import update from 'immutability-helper';
import _ from 'lodash';
import { CreateDeploymentRequestType, TransportationLanes, transportationLanesPayload } from 'models/DeploymentRequestTypes';
import { useCallback, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ReactComponent as CloneIcon } from '../../common/assets/clone.svg';

interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

export const TransportationLanesTable = (): JSX.Element => {
  const [{ value }, , { setValue }] = useField('transportationLanes');
  const [index, setIndex] = useState<number>(0);
  const [{ value: test }, , { setValue: setTest }] = useField<TransportationLanes>(`transportationLanes[${index}]`);

  const { values: transportationLanesValues, setValues } = useFormikContext<CreateDeploymentRequestType>();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isClone, setIsClone] = useState<boolean>(false);
  const [modal, contextHolder] = Modal.useModal();

  const type = 'DraggableBodyRow';

  const transportFormik = useFormik<TransportationLanes>({
    validationSchema: transportationLanesPayload,
    enableReinitialize: true,
    initialValues: {
      minimumDaysTransport: undefined,
      minimumServiceDaysExpedite: undefined,
      minimumServiceDaysStandard: undefined,
      deliveryMode: '',
      priority: value?.length ? value.length + 1 : 1
    },
    onSubmit: (values) => {
      if (
        transportationLanesValues?.transportationLanes?.find(
          (rule) =>
            rule.deliveryMode === values.deliveryMode &&
            rule.minimumDaysTransport === values.minimumDaysTransport &&
            rule.minimumServiceDaysExpedite === values.minimumServiceDaysExpedite &&
            rule.minimumServiceDaysStandard === values.minimumServiceDaysStandard
        )
      ) {
        message.error('Rule already exists, no duplicates allowed');

        return;
      }
      setValue([...value, values] as any);
      setIsOpen(false);
    }
  });
  const editFormik = useFormik<TransportationLanes>({
    validationSchema: transportationLanesPayload,
    enableReinitialize: true,
    initialValues: {
      minimumDaysTransport: test ? test.minimumDaysTransport : 0,
      minimumServiceDaysExpedite: test ? test.minimumServiceDaysExpedite : 0,
      minimumServiceDaysStandard: test ? test.minimumServiceDaysStandard : 0,
      deliveryMode: test ? test.deliveryMode : '',
      priority: test ? test.priority : 0
    },
    onSubmit: (values) => {
      if (
        transportationLanesValues?.transportationLanes?.find(
          (rule) =>
            rule.deliveryMode === values.deliveryMode &&
            rule.minimumDaysTransport === values.minimumDaysTransport &&
            rule.minimumServiceDaysExpedite === values.minimumServiceDaysExpedite &&
            rule.minimumServiceDaysStandard === values.minimumServiceDaysStandard
        )
      ) {
        message.error('Rule already exists, no duplicates allowed');

        return;
      }
      if (isClone) {
        setValues((prev) => ({
          ...prev,
          transportationLanes: [...value, values].map((row, i) => ({
            ...row,
            priority: i + 1
          }))
        }));
        setIsEditOpen(false);
        editFormik.resetForm();
        setIsClone(false);

        return;
      }
      setTest(values);
      setIsEditOpen(false);
      editFormik.resetForm();
    }
  });

  const handleCancel = (): void => {
    setIsOpen(false);
    setIsEditOpen(false);
    setIsClone(false);
    transportFormik.resetForm();
    editFormik.resetForm();
  };

  const handleDeleteRow = (record: TransportationLanes): void => {
    if (!transportationLanesValues.transportationLanes) return;

    setValues((prev) => ({
      ...prev,
      transportationLanes: transportationLanesValues.transportationLanes
        ? transportationLanesValues.transportationLanes.filter((item) => item !== record).map((item, i) => ({ ...item, priority: i + 1 }))
        : undefined
    }));
  };

  const handleEditRow = (record: TransportationLanes, index: number): void => {
    setIndex(index);
    setIsEditOpen(true);
  };
  const handleClone = (record: TransportationLanes, index: number): void => {
    setIndex(index);
    setIsClone(true);
    setIsEditOpen(true);
  };

  const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }: DraggableBodyRowProps): JSX.Element => {
    const ref = useRef<HTMLTableRowElement>(null);

    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};

        if (dragIndex === index) {
          return {};
        }

        return {
          isOver: monitor.isOver(),
          dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
        };
      },
      drop: (item: { index: number }) => {
        moveRow(item.index, index);
      }
    });
    const [, drag] = useDrag({
      type,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    });

    drop(drag(ref));

    return <tr ref={ref} className={`${className}${isOver ? dropClassName : ''}`} style={{ cursor: 'move', ...style }} {...restProps} />;
  };

  const cols = [
    {
      dataIndex: 'sort',
      width: 30,
      render: () => <HolderOutlined style={{ cursor: 'grab' }} />
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority'
    },
    {
      title: 'Min. Days Transport',
      dataIndex: 'minimumDaysTransport',
      align: 'center' as any,
      width: 170,
      key: 'minimumDaysTransport'
    },
    {
      title: 'Min. Days Expedite Service',
      dataIndex: 'minimumServiceDaysExpedite',
      align: 'center' as any,
      width: 170,
      key: 'minimumServiceDaysExpedite'
    },
    {
      title: 'Min. Days Standard Service',
      dataIndex: 'minimumServiceDaysStandard',
      align: 'center' as any,
      width: 170,
      key: 'minimumServiceDaysStandard'
    },
    {
      title: 'Delivery Mode',
      dataIndex: 'deliveryMode',
      width: 130,
      key: 'deliveryMode'
    },
    {
      title: 'Actions',
      width: 180,
      align: 'center' as any,
      render: (_: string, record: TransportationLanes, i: number) => (
        <Row justify="center" gutter={[1, 1]}>
          <Col>
            <Tooltip title="Clone Rule">
              <Button size="small" onClick={(): void => handleClone(record, i)} icon={<CloneIcon style={{ height: 25, width: 20 }} />} />
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title="Edit Rule">
              <Button size="small" onClick={(): void => handleEditRow(record, i)} icon={<EditOutlined />} />
            </Tooltip>
          </Col>
          <Col>
            {contextHolder}
            <Tooltip title="Delete Rule">
              <Button
                size="small"
                onClick={() =>
                  modal.confirm({
                    title: 'IF YOU CONTINUE, THE CHANGES WILL NOT GO INTO EFFECT UNTIL YOU CLICK SAVE.',
                    icon: <WarningFilled style={{ color: 'red' }} />,
                    okButtonProps: { style: { background: 'red', border: 'none' } },
                    okText: 'Delete',
                    onOk: () => handleDeleteRow(record),
                    prefixCls: 'ant-modal'
                  })
                }
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Col>
        </Row>
      )
    }
  ];

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (!transportationLanesValues?.transportationLanes) return;
      const dragRow = value[dragIndex];

      setValue(
        update(transportationLanesValues?.transportationLanes, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        }).map((d, i) => ({ ...d, priority: i + 1, key: i }))
      );
    },
    [transportationLanesValues, value]
  );

  const components = {
    body: {
      row: DraggableBodyRow
    }
  };

  return (
    <Card
      title={
        <Typography.Title level={4} style={{ fontWeight: 400, padding: 0, margin: 0 }}>
          Transportation Lanes Rules
        </Typography.Title>
      }
      headStyle={{ background: 'rgb(206, 213, 242)' }}
      style={{ height: '100%' }}
      extra={<Button onClick={(): void => setIsOpen(true)}>Add</Button>}>
      <DndProvider backend={HTML5Backend}>
        <FormikProvider value={transportFormik}>
          <Modal width={400} onCancel={handleCancel} onOk={(): Promise<void> => transportFormik.submitForm()} title="Add Transportation Lane Rule" open={isOpen}>
            <Form layout="vertical">
              <Space direction="vertical" size={15} style={{ width: '100%' }}>
                <TextInput
                  onKeyPress={(event): void => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  fieldName="minimumDaysTransport"
                  label="Min. Days Transport"
                />
                <TextInput
                  onKeyPress={(event): void => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  fieldName="minimumServiceDaysExpedite"
                  label="Min. Days Expedited Service "
                />
                <TextInput
                  onKeyPress={(event): void => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  fieldName="minimumServiceDaysStandard"
                  label="Min. Days Standard Service"
                />
                <DeliveryModeTypeSelect fieldName="deliveryMode" label="Delivery Mode" />
              </Space>
            </Form>
          </Modal>
        </FormikProvider>
        <FormikProvider value={editFormik}>
          <Modal
            width={400}
            onCancel={handleCancel}
            okButtonProps={{ disabled: _.isEqual(editFormik.initialValues, editFormik.values) }}
            onOk={(): Promise<void> => editFormik.submitForm()}
            title={!isClone ? 'Edit Transportation Lane Rule' : 'Add Transportation Lane Rule'}
            open={isEditOpen}>
            <Form layout="vertical">
              <Space direction="vertical" size={15} style={{ width: '100%' }}>
                <TextInput
                  onKeyPress={(event): void => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  fieldName="minimumDaysTransport"
                  label="Min. Days Transport"
                />
                <TextInput
                  onKeyPress={(event): void => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  fieldName="minimumServiceDaysExpedite"
                  label="Min. Days Expedited Service "
                />
                <TextInput
                  onKeyPress={(event): void => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  fieldName="minimumServiceDaysStandard"
                  label="Min. Days Standard Service"
                />
                <DeliveryModeTypeSelect fieldName="deliveryMode" label="Delivery Mode" />
              </Space>
            </Form>
          </Modal>
        </FormikProvider>
        <Table
          columns={cols}
          locale={{ emptyText: <AddRuleMessage /> }}
          pagination={false}
          dataSource={transportationLanesValues.transportationLanes}
          components={components}
          rowKey={(record): string => `${record.deliveryMode} - ${record.priority}`}
          onRow={(_, index): React.HTMLAttributes<any> => {
            const attr = {
              index,
              moveRow
            };

            return attr as React.HTMLAttributes<any>;
          }}
        />
      </DndProvider>
    </Card>
  );
};
