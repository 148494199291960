import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Card, Col, Form, Row, Space, Typography } from 'antd';
import { InventoryReservationMethodInput } from 'components/atoms/InventoryReservationMethodInput';
import { ConditionsSelectInput } from 'components/UI/FormItems/ConditionsSelectInput.tsx';
import { DispositionRuleSelectInput } from 'components/UI/FormItems/DispositionRuleSelect';
import { OwnersSelectInput } from 'components/UI/FormItems/OwnersSelectInput';
import { RequestTypeNameInput } from 'components/UI/FormItems/RequestTypeNameInput';
import { SalesOrderTypeSelect } from 'components/UI/FormItems/SalesOrderTypeSelect';
import { SwitchInputCard } from 'components/UI/FormItems/SwitchInputCard';
import { TextAreaInput } from 'components/UI/FormItems/TextAreaInput';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetDeploymentRequestConfigurationTypeQuery } from 'redux/services/drNobelPrice/drNobelPriceConfigTypes';

export const EditTypesForm: React.FC = () => {
  const { divisionId, typeId } = useParams();
  const { data: requestType } = useGetDeploymentRequestConfigurationTypeQuery(divisionId && typeId ? { divisionId, typeId } : skipToken);

  /* ******************** Renderer ******************** */
  return (
    <Row gutter={[10, 0]}>
      <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
        <Card
          style={{ height: '100%' }}
          headStyle={{ background: 'rgb(206, 213, 242)' }}
          title={
            <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
              General
            </Typography.Title>
          }>
          <Row gutter={[30, 0]}>
            <Col span={12}>
              <Form layout="vertical">
                <Space direction="vertical" style={{ width: '100%' }} size={15}>
                  <Col>
                    <RequestTypeNameInput requestName={requestType?.name} fieldName="name" label="Name" />
                  </Col>
                  <Col>
                    <DispositionRuleSelectInput fieldName="dispositionRuleType" label="Rule Type" />
                  </Col>
                  <Col>
                    <SalesOrderTypeSelect fieldName="salesOrderType" label="Sales Order Type" />
                  </Col>
                  <Col>
                    <InventoryReservationMethodInput />
                  </Col>
                  <Col>
                    <TextAreaInput fieldName="description" label="Description" />
                  </Col>
                </Space>
              </Form>
            </Col>
            <Col span={12}>
              <Form layout="vertical">
                <Space direction="vertical" style={{ width: '100%' }} size={15}>
                  <SwitchInputCard fieldName="isActive" label="Is Active" />
                  <SwitchInputCard fieldName="isOverageAllowed" label="Is Overage Allowed" />
                  <SwitchInputCard fieldName="isLabConfigurationRequired" label="Is Lab Configuration Utilized" />
                  <SwitchInputCard fieldName="isReleaseOrderRequired" label="Is Released Order Utilized" />
                  <SwitchInputCard fieldName="autoUseSubstitutionsIfQtyAvailable" label="Auto Use Substitutions If Qty Available" />
                </Space>
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
        <Card
          style={{ height: '100%' }}
          headStyle={{ background: '#FFEAC1' }}
          title={
            <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
              Dimension Defaults
            </Typography.Title>
          }>
          <Form layout="vertical">
            <Space direction="vertical" style={{ width: '100%' }}>
              <Col>
                <OwnersSelectInput fieldName="defaultOwnerId" label="Owner" />
              </Col>
              <Col>
                <ConditionsSelectInput mode="multiple" fieldName="defaultConditionIds" label="Conditions" />
              </Col>
            </Space>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
