import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeploymentRequestTypes } from 'models/DeploymentRequestTypes';

interface InitialState {
  selectedTypes: DeploymentRequestTypes[];
}

const initialState: InitialState = {
  selectedTypes: []
};

export const selectedTypes = createSlice({
  name: 'selectedTypes',
  initialState,
  reducers: {
    setSelectedTypes: (state, { payload }: PayloadAction<DeploymentRequestTypes[]>) => {
      state.selectedTypes = payload;
    },
    addSelectedTypes: (state, { payload }: PayloadAction<DeploymentRequestTypes>) => {
      state.selectedTypes = [...state.selectedTypes, payload];
    },
    removeSelectedTypes: (state, { payload }: PayloadAction<DeploymentRequestTypes>) => {
      state.selectedTypes = state.selectedTypes.filter((item) => item.id !== payload.id);
    }
  }
});

export const { setSelectedTypes, addSelectedTypes, removeSelectedTypes } = selectedTypes.actions;
