import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Menu, Row, Tooltip, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { CreateDeploymentRequestType } from 'models/DeploymentRequestTypes';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setEditTypePayloadId } from 'redux/slices/appSlice';

export const CreateTypesControls = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { divisionId } = useParams();

  const { values, submitForm } = useFormikContext<CreateDeploymentRequestType>();

  const handleBack = (): void => {
    navigate(`/${divisionId}/edit`);
  };

  const handleSave = async (close: boolean): Promise<void> => {
    if (close) {
      dispatch(setEditTypePayloadId(true));
    } else dispatch(setEditTypePayloadId(false));
    await submitForm();
  };

  const menuJSX = (
    <Menu disabledOverflow={true}>
      <Menu.Item key="1" onClick={(): Promise<void> => handleSave(false)}>
        Save
      </Menu.Item>
    </Menu>
  );

  return (
    <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
      <Col>
        <Typography.Title style={{ fontWeight: 400, padding: 0, margin: 0 }} level={3}>
          Deployment Request Type
        </Typography.Title>
      </Col>
      <Col>
        <Button style={{ marginRight: 8 }} onClick={handleBack}>
          Back to Configuration
        </Button>
        <Dropdown.Button
          disabled={!values.transportationLanes?.length || !values.warehouseFulfillmentRules?.length || !values.dimensionFulfillmentRules?.length}
          style={{ marginRight: 8 }}
          overlay={menuJSX}
          onClick={(): Promise<void> => handleSave(true)}
          type="primary"
          icon={<SaveOutlined />}>
          Save and Exit
        </Dropdown.Button>
        <Tooltip title="Coming soon">
          <Button type="primary" disabled>
            Audit History
          </Button>
        </Tooltip>
      </Col>
    </Row>
  );
};
