import { Col, Row } from 'antd';
import { DimensionFulfillmentTable } from 'components/molecules/DimensionFullfilmentTable.tsx';
import { TransportationLanesTable } from 'components/molecules/TransportationLanesTable';
import { WarehouseFulfillmentTable } from 'components/molecules/WarehouseFulfillmentTable';

export const RuleTables = (): JSX.Element => {
  return (
    <Row gutter={[10, 10]}>
      <Col span={12}>
        <WarehouseFulfillmentTable />
      </Col>
      <Col span={12}>
        <DimensionFulfillmentTable />
      </Col>
      <Col span={12}>
        <TransportationLanesTable />
      </Col>
    </Row>
  );
};
