import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Form, Select, SelectProps } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { useField } from 'formik';
import { useParams } from 'react-router-dom';
import { useGetDivisionQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps {
  fieldName: string;
  label?: string;
}

export const OwnersSelectInput = ({ fieldName, label, ...rest }: Props): JSX.Element => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<string>(fieldName);
  const { divisionId } = useParams();

  const { data: divisionData, isLoading, isFetching } = useGetDivisionQuery(divisionId ?? skipToken);

  const options = divisionData?.assignedOwners.map((item, i) => ({ label: item, value: item, key: i }));

  const handleChange = (value: string): void => {
    setValue(value);
  };

  if (isLoading) return <BlockLoader small direction="loader loader--slideUp" />;

  return (
    <Form.Item
      labelAlign="left"
      validateStatus={touched && error ? 'error' : 'success'}
      label={label ? <FormLabel label={label} /> : undefined}
      help={touched && error ? error : undefined}
      style={{ marginBottom: 0 }}
      labelCol={{ style: { padding: 0 } }}>
      <Select
        loading={isLoading || isFetching}
        showSearch
        allowClear
        value={value}
        options={options}
        onChange={handleChange}
        onFocus={(): void => setTouched(false)}
        onBlur={(): void => setTouched(true)}
        style={{ borderRadius: 5 }}
        {...rest}
      />
    </Form.Item>
  );
};
