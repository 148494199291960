import { Form, Select, SelectProps } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { useGetConditionsQuery } from 'redux/services/animals/animalsApi';
import { ReduxState } from 'redux/store';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps {
  fieldName: string;
  label?: string;
}

export const ConditionsSelectInput = ({ fieldName, label, ...rest }: Props): JSX.Element => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<string>(fieldName);

  const { animalsConditionsParams } = useSelector((state: ReduxState) => state);

  const { data: conditionsData, isLoading, isFetching } = useGetConditionsQuery({ ...animalsConditionsParams, overrideSkipTake: true });

  const conditionOptions = conditionsData?.data.map((item) => ({ label: item.name, value: item.name }));

  const handleChange = (value: string): void => {
    setValue(value);
  };

  if (isLoading) return <BlockLoader small direction="loader loader--slideUp" />;

  return (
    <Form.Item
      labelAlign="left"
      validateStatus={touched && error ? 'error' : 'success'}
      label={label ? <FormLabel label={label} /> : undefined}
      help={touched && error ? error : undefined}
      style={{ marginBottom: 0 }}
      labelCol={{ style: { padding: 0 } }}>
      <Select
        showSearch
        allowClear
        onChange={handleChange}
        value={value}
        loading={isLoading || isFetching}
        options={conditionOptions}
        {...rest}
        onFocus={(): void => setTouched(false)}
        onBlur={(): void => setTouched(true)}
        style={{ borderRadius: 5 }}
      />
    </Form.Item>
  );
};
