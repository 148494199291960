import { List } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { BusinessListItem } from 'components/molecules/BusinessListItem';
import { Business } from 'models/Business';
import { Division } from 'models/Division';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetBusinessesQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { ReduxState, useAppSelector } from 'redux/store';

export const BusinessList = (): JSX.Element => {
  const { isTriggerLoading } = useSelector((state: ReduxState) => state.app);
  const { abbyCadabbyParams } = useSelector((state: ReduxState) => state);
  const { customerSearch } = useAppSelector((state) => state.businessList);
  const { data, isLoading, isFetching } = useGetBusinessesQuery(abbyCadabbyParams);

  const businessData = data?.data;

  const filteredData = useMemo(() => {
    let returnData: Business[] = [];

    // If business and divisions loaded
    if (businessData) {
      for (const business of businessData) {
        // Map the divisions
        let fullDivisions: Division[] = [];

        for (const division of business.divisions) {
          fullDivisions = [...fullDivisions, ...[division]] as any;
        }

        // Add business filtered for customer search
        returnData = [...returnData, { ...business, divisions: fullDivisions }].map((business) => ({
          ...business,
          divisions: business.divisions.filter((division) => !customerSearch || division.name.toLowerCase().includes(customerSearch) || division.erpId.toLowerCase().includes(customerSearch))
        }));
      }
    }

    // Only return the search values that contain divisions
    const filteredReturnData = customerSearch?.length ? returnData.filter((business) => business.divisions.length) : returnData;

    return filteredReturnData;
  }, [businessData, customerSearch]);

  return (
    <>
      <List
        loading={{
          indicator: <BlockLoader message={isTriggerLoading ? 'Refreshing customer list' : 'Loading customers'} direction="loader loader--slideUp" />,
          size: 'large',
          spinning: isLoading || isFetching || isTriggerLoading
        }}
        dataSource={filteredData}
        renderItem={(item): JSX.Element => <BusinessListItem business={item} />}
        rowKey={(item: any): string => item.id}
      />
    </>
  );
};
