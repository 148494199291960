import { skipToken } from '@reduxjs/toolkit/dist/query';
import { message, Space } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { CreateTypesControls } from 'components/molecules/CreateTypesControls';
import { CreateTypesForm } from 'components/molecules/CreateTypesForm';
import { RuleTables } from 'components/organisms/RuleTables';
import { FormikProvider, useFormik } from 'formik';
import { CreateDeploymentRequestType, requestTypePayload } from 'models/DeploymentRequestTypes';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetDeploymentRequestConfigurationQuery } from 'redux/services/drNobelPrice/drNobelPriceConfig';
import { useCreateDeploymentRequestConfigurationTypeMutation } from 'redux/services/drNobelPrice/drNobelPriceConfigTypes';
import { ReduxState } from 'redux/store';

export const CreateTypesPage = (): JSX.Element => {
  const { divisionId } = useParams();
  const navigate = useNavigate();

  const { editTypePayloadId } = useSelector((state: ReduxState) => state.app);

  const { data } = useGetDeploymentRequestConfigurationQuery(divisionId ? divisionId : skipToken);

  const [triggerUpdate, { isLoading: isCreating }] = useCreateDeploymentRequestConfigurationTypeMutation();

  const arr = ['SO']; // Replace with api call when hooked up

  const formik2 = useFormik<CreateDeploymentRequestType>({
    enableReinitialize: true,
    validationSchema: requestTypePayload,
    initialValues: {
      defaultConditionIds: [],
      defaultOwnerId: '',
      description: '',
      dimensionFulfillmentRules: [],
      inventoryReservationMethod: 'Automatic',
      transportationLanes: [],
      dispositionRuleType: data?.allowedDeploymentRequestTypes.length === 1 ? data.allowedDeploymentRequestTypes[0] : '',
      isActive: true,
      isOverageAllowed: false,
      autoUseSubstitutionsIfQtyAvailable: false,
      name: '',
      salesOrderType: arr.length === 1 ? arr[0] : '',
      warehouseFulfillmentRules: []
    },
    onSubmit: async (values) => {
      try {
        if (!divisionId) return message.error('Division cannot be found');
        const newType = await triggerUpdate({ divisionId, payload: { ...values, dispositionRuleType: values?.dispositionRuleType ? values?.dispositionRuleType.replace(/\s/g, '') : '' } }).unwrap();

        message.success(`${newType.name} request type successfully created`);
        if (editTypePayloadId) navigate(`/${divisionId}/edit`);
        else navigate(`/${divisionId}/types/${newType.id}/edit`);
      } catch (err) {
        console.log(err);
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      }
    }
  });

  if (isCreating) return <BlockLoader direction="loader loader--slideUp" />;

  return (
    <FormikProvider value={formik2}>
      <Space direction="vertical">
        <CreateTypesControls />
        <CreateTypesForm />
        <RuleTables />
      </Space>
    </FormikProvider>
  );
};
