import * as yup from 'yup';

export interface DeploymentRequestTypes {
  auditHistory: string[];
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  defaultConditionIds: string[];
  defaultOwnerId: string;
  description: string;
  inventoryReservationMethod: string;
  dimensionFulfillmentRules: DimensionFulfillmentRules[];
  transportationLanes: TransportationLanes[];
  dispositionRuleType: string;
  division: {
    business: {
      dataAreaId: string;
      erpId: string;
      id: string;
      name: string;
    };
    erpId: string;
    id: string;
    name: string;
  };
  documentType: string;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  isOverageAllowed: boolean;
  isReleaseOrderRequired: boolean;
  isLabConfigurationRequired: boolean;
  autoUseSubstitutionsIfQtyAvailable: boolean;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  name: string;
  partitionKey: string;
  partitionKeyDescription: string;
  salesOrderType: string;
  warehouseFulfillmentRules: WarehouseFulfillmentRules[];
  _rid: string;
  _self: string;
  _etag: string;
  _attachments: string;
  _ts: number;
}

export interface EditDeploymentRequestType {
  name?: string;
  description?: string;
  dispositionRuleType?: string;
  salesOrderType?: string;
  isActive?: boolean;
  isOverageAllowed?: boolean;
  autoUseSubstitutionsIfQtyAvailable?: boolean;
  defaultOwnerId?: string;
  defaultConditionIds: string[];
  warehouseFulfillmentRules: WarehouseFulfillmentRules[];
  dimensionFulfillmentRules: DimensionFulfillmentRules[];
  transportationLanes: TransportationLanes[];
  inventoryReservationMethod: string;
  isReleaseOrderRequired: boolean;
  isLabConfigurationRequired: boolean;
}

export interface WarehouseFulfillmentRules {
  countryCode?: string;
  warehouseId?: string;
  postalCode?: string | null;
  priority?: number;
  siteId?: string;
  subdivisionCode?: string;
}
export interface DimensionFulfillmentRules {
  conditionId?: string;
  dispositionId?: string;
  isDefault?: boolean;
  isCommonStock?: boolean;
  ownerId?: string;
  priority?: number;
}

export interface CreateDeploymentRequestType {
  defaultConditionIds?: string[];
  defaultOwnerId?: string;
  description?: string;
  dispositionRuleType?: string;
  isActive?: boolean;
  autoUseSubstitutionsIfQtyAvailable?: boolean;
  isOverageAllowed?: boolean;
  name: string;
  salesOrderType: string;
  warehouseFulfillmentRules?: WarehouseFulfillmentRules[];
  dimensionFulfillmentRules?: DimensionFulfillmentRules[];
  transportationLanes?: TransportationLanes[];
  inventoryReservationMethod: string;
}

export interface TransportationLanes {
  auditHistory?: string[];
  minimumDaysTransport?: number;
  minimumServiceDaysExpedite?: number;
  minimumServiceDaysStandard?: number;
  priority?: number;
  deliveryMode: string;
}

export interface DeploymentRequestTypesParams {
  isOverageAllowed?: boolean;
  nameContains?: string;
  salesOrderTypeEquals?: string;
  dispositionRuleTypeEquals?: string;
  includeInactiveData?: boolean;
  returnInactiveDataOnly?: boolean;
  includeDeletedData?: boolean;
  returnDeletedDataOnly?: boolean;
}

export const requestTypePayload: yup.SchemaOf<CreateDeploymentRequestType> = yup.object({
  name: yup.string().label('Name').required(),
  inventoryReservationMethod: yup.string().label('').required(),
  salesOrderType: yup.string().label('Sales Order Type').required(),
  defaultConditionIds: yup.array().label('Default Conditions').min(1).required(),
  defaultOwnerId: yup.string().label('Default Owner').required(),
  description: yup.string().label('Description'),
  dispositionRuleType: yup.string().label('Disposition Rule Type').required(),
  isActive: yup.boolean().label('Is Active'),
  autoUseSubstitutionsIfQtyAvailable: yup.boolean().label('Auto Use Substitutions If Qty Available'),
  isOverageAllowed: yup.boolean().label('Is Overage Allowed'),
  warehouseFulfillmentRules: yup.array().label('Wareouse Fulfillment Rules'),
  dimensionFulfillmentRules: yup.array().label('Dimension Fulfillment Rules').min(1).required(),
  transportationLanes: yup.array().label('transportationLanes')
});

export const fulfillmentRulesPayload: yup.SchemaOf<DimensionFulfillmentRules> = yup.object({
  ownerId: yup.string().label('Owner').required(),
  conditionId: yup.string().label('Condition').required(),
  dispositionId: yup.string().label('Disposition').required(),
  isDefault: yup.boolean().label('Is Default').required(),
  isCommonStock: yup.boolean().label('Is Common Stock').required(),
  priority: yup.number().label('Priority')
});

export const transportationLanesPayload: yup.SchemaOf<TransportationLanes> = yup.object({
  deliveryMode: yup.string().label('Delivery Mode').required(),
  minimumDaysTransport: yup.number().label('Minimum Days Transport').required(),
  minimumServiceDaysExpedite: yup.number().label('Minimum Expidite Days').required(),
  minimumServiceDaysStandard: yup.number().label('Minimum Standard Days').required(),
  auditHistory: yup.array().label('Is Default'),
  priority: yup.number().label('Priority')
});

export const warehouseFulfillmentRulesPayload: yup.SchemaOf<WarehouseFulfillmentRules> = yup.object({
  countryCode: yup.string().label('Country').required(),
  warehouseId: yup.string().label('Warehouse').required(),
  postalCode: yup.string().label('Postal Code'),
  siteId: yup.string().label('Site').required(),
  subdivisionCode: yup.string().label('State'),
  priority: yup.number().label('Priority')
});
