import { skipToken } from '@reduxjs/toolkit/dist/query';
import { message, Space, Spin } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { EditTypeControls } from 'components/molecules/EditTypeControls';
import { EditTypesForm } from 'components/molecules/EditTypeForm';
import { RuleTables } from 'components/organisms/RuleTables';
import { FormikProvider, useFormik } from 'formik';
import { EditDeploymentRequestType } from 'models/DeploymentRequestTypes';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetDeploymentRequestConfigurationTypeQuery, useUpdateDeploymentRequestConfigurationTypeMutation } from 'redux/services/drNobelPrice/drNobelPriceConfigTypes';
import { ReduxState } from 'redux/store';

export const EditTypesPage = (): JSX.Element => {
  const navigate = useNavigate();

  const { isClose } = useSelector((state: ReduxState) => state.app);

  const { divisionId, typeId } = useParams();
  const { data: requestType, isLoading, isFetching } = useGetDeploymentRequestConfigurationTypeQuery(divisionId && typeId ? { divisionId, typeId } : skipToken);

  const [triggerUpdate, { isLoading: isUpdating }] = useUpdateDeploymentRequestConfigurationTypeMutation();

  const formik = useFormik<EditDeploymentRequestType>({
    enableReinitialize: true,
    initialValues: {
      name: requestType?.name ?? '',
      description: requestType?.description ?? '',
      dispositionRuleType: requestType?.dispositionRuleType ?? '',
      inventoryReservationMethod: requestType?.inventoryReservationMethod ?? '',
      salesOrderType: requestType?.salesOrderType ?? '',
      isActive: requestType?.isActive ?? false,
      isLabConfigurationRequired: requestType?.isLabConfigurationRequired ?? false,
      isReleaseOrderRequired: requestType?.isReleaseOrderRequired ?? false,
      autoUseSubstitutionsIfQtyAvailable: requestType?.autoUseSubstitutionsIfQtyAvailable ?? false,
      isOverageAllowed: requestType?.isOverageAllowed ?? false,
      defaultOwnerId: requestType?.defaultOwnerId ?? '',
      defaultConditionIds: requestType?.defaultConditionIds ?? [],
      warehouseFulfillmentRules: requestType?.warehouseFulfillmentRules ?? [],
      dimensionFulfillmentRules: requestType?.dimensionFulfillmentRules ?? [],
      transportationLanes: requestType?.transportationLanes ?? []
    },
    onSubmit: async (values) => {
      if (!divisionId || !typeId) return;
      try {
        const updatedType = await triggerUpdate({ divisionId, deploymentRequestTypeId: typeId, payload: { ...requestType, ...values } }).unwrap();

        message.success(`${updatedType.name} successfully updated`);
        if (isClose) navigate(`/${divisionId}/edit`);
      } catch (err) {
        console.log(err);
        message.error((err as { data: { errorMessage: string } })?.data.errorMessage ?? 'Could not be updated at this time');
      }
    }
  });

  if (isLoading || isFetching) return <BlockLoader direction="loader loader--slideUp" />;

  return (
    <FormikProvider value={formik}>
      <Space direction="vertical">
        <Spin spinning={isUpdating} indicator={<BlockLoader direction="loader loader--slideUp" />}>
          <EditTypeControls />
          <EditTypesForm />
          <RuleTables />
        </Spin>
      </Space>
    </FormikProvider>
  );
};
