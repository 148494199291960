import { DeleteOutlined, SaveOutlined, WarningFilled } from '@ant-design/icons';
import { Button, Col, Dropdown, Menu, message, Modal, Row, Tooltip, Typography } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { useFormikContext } from 'formik';
import { EditDeploymentRequestType } from 'models/DeploymentRequestTypes';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteDeploymentRequestConfigurationTypeMutation } from 'redux/services/drNobelPrice/drNobelPriceConfigTypes';
import { setIsClose } from 'redux/slices/appSlice';

export const EditTypeControls = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { divisionId, typeId } = useParams();

  const [triggerDelete, { isLoading: isDeleting }] = useDeleteDeploymentRequestConfigurationTypeMutation();

  const [modal, contextHolder] = Modal.useModal();

  const { values, submitForm } = useFormikContext<EditDeploymentRequestType>();

  const handleBack = (): void => {
    navigate(`/${divisionId}/edit`);
  };

  const handleSave = async (close: boolean): Promise<void> => {
    if (close) dispatch(setIsClose(true));

    await submitForm();
  };
  const handleDeleteType = async (): Promise<void> => {
    if (!divisionId || !typeId) return;
    try {
      await triggerDelete({ divisionId, deploymentRequestTypeId: typeId, params: { wouldYouLikeToPlayAGame: false } });
      message.success('Type successfully deleted');
      handleBack();
    } catch (err) {
      console.log(err);
      message.error((err as { data: { errorMessage: string } })?.data.errorMessage ?? 'Type could not be deleted at this time');
    }
  };

  const menuJSX = (
    <Menu disabledOverflow={true}>
      <Menu.Item key="1" onClick={(): Promise<void> => handleSave(false)}>
        Save
      </Menu.Item>
    </Menu>
  );

  return (
    <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
      <Col>
        <Typography.Title style={{ fontWeight: 400, padding: 0, margin: 0 }} level={3}>
          Deployment Request Type
        </Typography.Title>
      </Col>
      <Col>
        <Button style={{ marginRight: 5 }} onClick={handleBack}>
          Back
        </Button>
        <Dropdown.Button
          disabled={!values.transportationLanes.length || !values.warehouseFulfillmentRules.length || !values.dimensionFulfillmentRules.length}
          style={{ marginRight: 8 }}
          overlay={menuJSX}
          onClick={(): Promise<void> => handleSave(true)}
          type="primary"
          icon={<SaveOutlined />}>
          Save and Exit
        </Dropdown.Button>
        {contextHolder}
        <Button
          loading={isDeleting}
          danger
          onClick={(): void => {
            modal.confirm({
              title: isDeleting ? <BlockLoader direction="loader loader--slideUp" /> : 'If you continue this type will be sent to the recycle bin where it can be recovered or deleted permanently',
              onOk: handleDeleteType,
              okText: 'Delete',
              icon: <WarningFilled style={{ color: 'red' }} />,
              okButtonProps: { style: { background: 'red', border: 'none' } },
              prefixCls: 'ant-modal'
            });
          }}
          icon={<DeleteOutlined />}
          style={{ color: 'red', marginRight: 8 }}>
          Delete
        </Button>

        <Tooltip title="Coming soon">
          <Button type="primary" disabled>
            Audit History
          </Button>
        </Tooltip>
      </Col>
    </Row>
  );
};
