import { Card, Col, Form, Space, Typography } from 'antd';
import { InstallAddresesSelect } from 'components/UI/FormItems/InstallAddressesSelect';
import { ShippingAddressesSelect } from 'components/UI/FormItems/ShippingAddressesSelect';
import { SwitchInputCard } from 'components/UI/FormItems/SwitchInputCard';
import React from 'react';

export const AddressesCard: React.FC = () => {
  /* ******************** Renderer ******************** */
  return (
    <Col span={6} xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
      <Card
        title={
          <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
            Addresses
          </Typography.Title>
        }
        bodyStyle={{ padding: 20 }}
        headStyle={{ background: '#FFEAC1' }}
        style={{ height: '100%' }}>
        <Form layout="vertical">
          <Space size={14} direction="vertical" style={{ width: '100%' }}>
            <SwitchInputCard fieldName="addressDetails.istManualAddressEntryAllowed" label="Allow Manual Address Entry" />
            <SwitchInputCard fieldName="addressDetails.isManualAddressAllowedToBeAddedToPostOffice" label="Add Manual Addresses to Post Office" />
            <SwitchInputCard fieldName="addressDetails.displayAddressesByDefault" label="Display Addresses by Default" />
            <InstallAddresesSelect mode="multiple" fieldName="addressDetails.installAddressTypes" label="Install Types" />
            <ShippingAddressesSelect mode="multiple" fieldName="addressDetails.shippingAddressTypes" label="Shipping Types" />
          </Space>
        </Form>
      </Card>
    </Col>
  );
};
