import { Form, Input, InputProps } from 'antd';
import { useField } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetDeploymentRequestConfigurationTypesQuery } from 'redux/services/drNobelPrice/drNobelPriceConfigTypes';
import { ReduxState } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';
import { FormLabel } from './FormLabel';

interface Props extends InputProps {
  fieldName: string;
  label?: string;
  requestName?: string;
}

export const RequestTypeNameInput = ({ fieldName, label, requestName, ...rest }: Props): JSX.Element => {
  const [{ value }, { touched, error }, { setValue, setTouched, setError }] = useField<string>(fieldName);
  const [unavailableNames, setUnavailableNames] = useState<string[]>([]);
  const [localValue, setLocalValue] = useState(value);
  const { deploymentRequestTypesParams } = useSelector((state: ReduxState) => state);
  const { divisionId } = useParams();

  const { data: testTypes, isLoading, isFetching } = useGetDeploymentRequestConfigurationTypesQuery({ divisionId: divisionId as string, params: deploymentRequestTypesParams });

  const debounce = useDebouncedCallback((input: string): void => {
    setValue(input);
  }, 500);

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setLocalValue(e.target.value);
    debounce(e.target.value);
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    if (testTypes && !unavailableNames.length) {
      const filteredNames = testTypes.data.map((type) => type.name.toLowerCase());

      setUnavailableNames(filteredNames);
    }
  }, [testTypes, unavailableNames]);

  const nameCheck = unavailableNames.includes(value.toLowerCase()) && value.toLowerCase() !== requestName?.toLowerCase();

  return (
    <Form.Item
      labelAlign="left"
      validateStatus={error || nameCheck ? 'error' : 'success'}
      label={label ? <FormLabel label={label} /> : undefined}
      help={error ? error : undefined || (nameCheck && 'Deployment Request Type name already exists.')}
      style={{ marginBottom: 0 }}
      labelCol={{ style: { padding: 0 } }}>
      <Input {...rest} value={localValue || undefined} allowClear onChange={handleChange} onFocus={(): void => setTouched(false)} onBlur={(): void => setTouched(true)} style={{ borderRadius: 5 }} />
    </Form.Item>
  );
};
