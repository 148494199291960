import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, List, Row, Tooltip, Typography } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { DeploymentRequestTypes } from 'models/DeploymentRequestTypes';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useDispatch, useSelector } from 'react-redux';
import { addSelectedTypes, removeSelectedTypes } from 'redux/slices/selectedTypeSlice';
import { ReduxState } from 'redux/store';
import EllipsesText from './EllipsiesText';

const styles: InlineStylesModel = {
  card: {
    backgroundColor: 'white',
    borderRadius: 5
  },
  categoryId: {
    color: 'grey',
    lineHeight: '.7',
    paddingBottom: '13px'
  },
  manufacturer: {
    fontWeight: 600
  },
  dispositionRuleType: {
    color: 'grey',
    height: '15px'
    // marginBottom: 30
  },
  partDescription: {
    color: 'grey'
  },
  itemName: {
    fontWeight: 600,
    fontSize: 20,
    color: '#203175'
  },
  salesOrderType: {
    color: 'grey',
    textAlign: 'right'
  },
  robotPadding: {
    paddingTop: 5,
    cursor: 'pointer'
  },
  smallGrouping: {
    color: 'grey',
    fontSize: '8',
    background: '#8383831a',
    padding: 4,
    borderRadius: 100
  },
  view: {
    color: '#203175',
    fontWeight: 600,
    fontSize: '15px',
    cursor: 'pointer'
  },
  test: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
};

interface Props {
  type: DeploymentRequestTypes;
}

const RecyclingBinCard = ({ type }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { selectedTypes } = useSelector((state: ReduxState) => state.selectedTypes);

  const isSelected = selectedTypes.some((item: DeploymentRequestTypes) => item.id === type.id);

  const handleToggleSelectProduct = (): void => {
    if (isSelected) {
      dispatch(removeSelectedTypes(type));
    } else {
      dispatch(addSelectedTypes(type));
    }
  };

  const readOnlyCardJSX = (
    <>
      <Row justify="space-between" align="middle" style={{ border: 'none', margin: 0 }}>
        <Typography.Text style={styles.largeText}>
          <Tooltip title="Application Name" placement="right" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
            {type.name}
          </Tooltip>
        </Typography.Text>
        <Button style={{ borderRadius: '50%' }} onClick={handleToggleSelectProduct} type={isSelected ? 'primary' : 'default'} icon={isSelected ? <CheckOutlined /> : ' '} />
      </Row>
      <Card bordered={false} style={{ margin: 0 }}>
        <Meta
          style={styles.meta}
          description={
            <Typography.Paragraph style={{ marginBottom: 0, color: 'rgb(116, 120, 141)' }} ellipsis={{ rows: 1, tooltip: type.description }}>
              {type.description ? type.description : 'No Description Available'}
            </Typography.Paragraph>
          }
        />
      </Card>
    </>
  );

  const card = (
    <Col>
      <Card hoverable style={styles.card} onClick={handleToggleSelectProduct}>
        <Row gutter={[2, 0]} justify="space-between" align="middle">
          <Col style={styles.itemName} span={12}>
            <EllipsesText tooltipCharacterCount={23} text={type.name} />
          </Col>
          <Col style={styles.salesOrderType} span={12}>
            <Button style={{ borderRadius: '50%' }} onClick={handleToggleSelectProduct} type={isSelected ? 'primary' : 'default'} icon={isSelected ? <CheckOutlined /> : ' '} />
          </Col>
        </Row>
        <Row justify="space-between" align="top"></Row>
        <Row style={styles.dispositionRuleType}>{type.dispositionRuleType !== '' ? type.name : 'N/A'}</Row>
        <Row justify="center">
          <Col span={24} style={{ marginBottom: 10 }}>
            <Divider />
          </Col>
        </Row>
        <Row justify="space-between" align="bottom">
          <Col>
            <Row style={styles.smallGrouping} gutter={16} align="middle">
              <Col>{type.name}</Col>
              <Divider style={{ marginTop: 3 }} type="vertical" />
              <Col>{type.name}</Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Col>
  );

  return (
    <List.Item style={styles.wrapper} colStyle={{ height: '100%', marginBottom: 8, padding: 4 }}>
      {/* <Card hoverable onClick={handleToggleSelectProduct} style={isSelected ? styles.selectedCard : styles.card} bodyStyle={styles.cardBody}>
        {readOnlyCardJSX}
      </Card> */}
      {card}
    </List.Item>
  );
};

export default RecyclingBinCard;
