import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, message, Modal, Row, Space, Spin, Typography } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useDeleteDeploymentRequestConfigurationTypeMutation,
  useGetDeploymentRequestConfigurationTypesQuery,
  useRecoverDeploymentRequestConfigurationTypeMutation
} from 'redux/services/drNobelPrice/drNobelPriceConfigTypes';
import { setSelectedTypes } from 'redux/slices/selectedTypeSlice';
import { ReduxState } from 'redux/store';

const RecoverActions = (): JSX.Element => {
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const [showPurgeModal, _setShowPurgeModal] = useState(false);
  const [applicationCount, _setApplicationCount] = useState(1);
  const { selectedTypes } = useSelector((state: ReduxState) => state.selectedTypes);
  const { divisionId } = useParams();
  const { deploymentRequestTypesParams } = useSelector((state: ReduxState) => state);
  const { data, isLoading, isFetching } = useGetDeploymentRequestConfigurationTypesQuery(
    divisionId ? { divisionId, params: { ...deploymentRequestTypesParams, returnDeletedDataOnly: true } } : skipToken
  );

  const [recoverType, { isLoading: isRecoveringTyp }] = useRecoverDeploymentRequestConfigurationTypeMutation();
  const [purgeType, { isLoading: isPurgingType }] = useDeleteDeploymentRequestConfigurationTypeMutation();

  const totalSelectedTypes = selectedTypes.length;

  const handleRestoreTypes = async (): Promise<void> => {
    if (!divisionId) return;
    if (selectedTypes.length < 1) {
      message.error('No entity selected!');

      return;
    }

    for (const type of selectedTypes) {
      try {
        await recoverType({ divisionId, deploymentRequestTypeId: type.id }).unwrap();
      } catch {
        message.error(`Type ${type.name} failed to be recovered!`);
      }
    }

    dispatch(setSelectedTypes([]));
    message.success(`Types(s) were successfully recovered!`);
  };

  const handlePurgeItems = async (): Promise<void> => {
    if (!divisionId) return;
    if (selectedTypes.length < 1) {
      message.error('No items have been selected to be deleted!');

      return;
    }
    for (const type of selectedTypes) {
      try {
        const test = await purgeType({ divisionId, deploymentRequestTypeId: type.id, params: { wouldYouLikeToPlayAGame: true } });

        if ((test as any).error) {
          throw new Error((test as any).error);
        }

        _setApplicationCount((prev) => prev + 1);
        message.success(`Purged Types(s) successfully!`);
      } catch (error) {
        message.error(`Failed to delete Type ${type.name}`);
      }
    }

    _setShowPurgeModal(false);
    _setApplicationCount(1);
    dispatch(setSelectedTypes([]));
  };

  const handleSelectAll = (): void => {
    if (data) {
      dispatch(setSelectedTypes(data.data));
    }
  };

  const handleClearAll = (): void => {
    dispatch(setSelectedTypes([]));
  };

  return (
    <Row justify="space-between" style={{ marginTop: 10 }}>
      <Row justify="space-between">
        <Button disabled={!data?.data.length} onClick={handleSelectAll} style={{ marginRight: 5 }} type="primary">
          Select All
        </Button>
        <Button danger ghost disabled={!totalSelectedTypes} onClick={handleClearAll} style={{ marginRight: 5 }}>
          Clear All
        </Button>
      </Row>
      <Row justify="space-between">
        <Button
          onClick={handleRestoreTypes}
          style={totalSelectedTypes ? { marginRight: 5, background: '#4e937a', borderColor: '#4e937a', color: 'white' } : { marginRight: 5 }}
          loading={isRecoveringTyp}
          disabled={!totalSelectedTypes}>
          Restore Selected ({totalSelectedTypes})
        </Button>
        <Button disabled={!totalSelectedTypes} onClick={(): void => _setShowPurgeModal(!showPurgeModal)} danger loading={isPurgingType}>
          Purge Selected ({totalSelectedTypes})
        </Button>
      </Row>
      <Modal
        title="Are you sure?"
        okText={`Purge Items (${totalSelectedTypes})`}
        open={showPurgeModal}
        onOk={handlePurgeItems}
        closable={!isPurgingType}
        maskClosable={!isPurgingType}
        onCancel={(): void => _setShowPurgeModal(false)}
        cancelButtonProps={isPurgingType ? { style: { pointerEvents: 'none', opacity: '.3' } } : undefined}
        okButtonProps={isPurgingType ? { style: { background: '#dc3545', pointerEvents: 'none', opacity: '.3', border: 'none' } } : { style: { background: '#dc3545', border: 'none' } }}>
        <Spin
          spinning={isPurgingType}
          indicator={
            <Space style={{ width: '100%', marginLeft: '-50%', marginTop: '-37%' }}>
              <BlockLoader direction="loader loader--slideUp" message={`Purging Type ${applicationCount} of ${totalSelectedTypes}...`} />
            </Space>
          }>
          <Typography.Text type="danger">IF YOU CONTINUE, YOU WILL NO LONGER BE ABLE TO RECOVER THE SELECTED CONFIGURATION TYPES.</Typography.Text>
        </Spin>
      </Modal>
    </Row>
  );
};

export default RecoverActions;
