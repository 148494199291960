import { RuleFilter } from 'models/DeploymentRequestConfig';
import { useEffect } from 'react';
import { setDispositionRuleTypeEquals } from 'redux/services/drNobelPrice/requestTypeParams';
import { useAppDispatch } from 'redux/store';
import { FancySelect } from '../Inputs/FancySelect';

export const DispositionRuleFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  /* ******************** Functions ******************** */
  const handleChange = (value: string): void => {
    dispatch(setDispositionRuleTypeEquals(value || undefined));
  };

  const options = [RuleFilter.Disposition, RuleFilter.AllProjects, RuleFilter.SpecificProjects].map((item) => ({ label: item, value: item.replace(/\s/g, '') }));

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setDispositionRuleTypeEquals(undefined));
    };
  }, [dispatch]);

  return <FancySelect options={options} placeholder="Disposition Rule" onSelect={handleChange} />;
};
