import { MailOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Form, List, Row, Tooltip, Typography } from 'antd';
import { CaretSpinIcon } from 'components/atoms/CaretSpinIcon';
import { EmailInput } from 'components/atoms/EmailInput';
import { SwitchInputCard } from 'components/UI/FormItems/SwitchInputCard';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { UpsertDeploymentRequestConfigurationPayload } from 'models/DeploymentRequestConfig';
import React, { LegacyRef, MouseEvent, Ref, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import * as yup from 'yup';

interface ShipmentEmails {
  shipmentEmail?: string;
}
interface ReleasedEmails {
  releaseEmail?: string;
}
const shipmentPayload: yup.SchemaOf<ShipmentEmails> = yup.object({
  shipmentEmail: yup.string().email('Please enter a valid email address').strict().required('This field is required')
});
const releasePayload: yup.SchemaOf<ReleasedEmails> = yup.object({
  releaseEmail: yup.string().email('Please enter a valid email address').strict().required('This field is required')
});

export const NotificationsCard: React.FC = () => {
  const { values } = useFormikContext<UpsertDeploymentRequestConfigurationPayload>();
  const [showNotifications, setShowNotifications] = useState(false);

  const [{ value: rValue }, , { setValue: setReleaseValue }] = useField<string[]>('notifications.releaseNotifications.emailAddresses');
  const [{ value: sValue }, , { setValue: setShipmentValue }] = useField<string[]>('notifications.shipmentNotifications.emailAddresses');

  const shipmentEmailFormik = useFormik<ShipmentEmails>({
    enableReinitialize: true,
    validationSchema: shipmentPayload,
    initialValues: {
      shipmentEmail: ''
    },
    onSubmit: () => {
      try {
        if (sValue.includes(shipmentEmailFormik.values.shipmentEmail as string)) {
          shipmentEmailFormik.setErrors({ shipmentEmail: 'The email you entered already exists.' });
          throw new Error('The email you entered already exists.');
        }
        if (!values?.notifications?.shipmentNotifications.emailAddresses || !shipmentEmailFormik.values.shipmentEmail) return;
        setShipmentValue([...sValue, shipmentEmailFormik.values.shipmentEmail]);
        shipmentEmailFormik.setFieldValue('shipmentEmail', '');
        shipmentEmailFormik.resetForm();
      } catch (e) {
        console.log(e);
      }
    }
  });
  const releaseEmailFormik = useFormik<ReleasedEmails>({
    enableReinitialize: true,
    validationSchema: releasePayload,
    initialValues: {
      releaseEmail: ''
    },
    onSubmit: () => {
      try {
        if (rValue.includes(releaseEmailFormik.values.releaseEmail as string)) {
          releaseEmailFormik.setErrors({ releaseEmail: 'The email you entered already exists.' });
          throw new Error('The email you entered already exists.');
        }
        if (!values?.notifications?.releaseNotifications.emailAddresses || !releaseEmailFormik.values.releaseEmail) return;
        setReleaseValue([...rValue, releaseEmailFormik.values.releaseEmail]);
        releaseEmailFormik.setFieldValue('releaseEmail', '');
        releaseEmailFormik.resetForm();
      } catch (e) {
        console.log(e);
      }
    }
  });

  const handleAddRow = (): void => {
    shipmentEmailFormik.setFieldTouched('shipmentEmail', true, true);
    shipmentEmailFormik.submitForm();
  };
  const handleAddReleaseRow = (): void => {
    releaseEmailFormik.setFieldTouched('releaseEmail', true, true);
    releaseEmailFormik.submitForm();
  };

  const handleShipmentEmailDelete = (item: string): void => {
    const filteredArr = sValue.filter((id: string) => id !== item);

    setShipmentValue(filteredArr);
  };

  const handleReleaseEmailDelete = (item: string): void => {
    const filteredArr = rValue.filter((id: string) => id !== item);

    setReleaseValue(filteredArr);
  };

  const handleToggleShowNotifications = (ev: MouseEvent<HTMLElement>): void => {
    ev.preventDefault();
    ev.stopPropagation();
    setShowNotifications((prevState) => !prevState);
  };

  const [ref, { height }] = useMeasure();
  const [shipmentRef, { height: shipmentHeight }] = useMeasure();
  const [releasedRef, { height: releasedHeight }] = useMeasure();

  const expandAnimation = useSpring({
    config: { friction: 12 },
    height: showNotifications ? height : 0
  });

  const shipmentEmailJSX: JSX.Element = (
    <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} ref={shipmentRef as Ref<HTMLDivElement>}>
      <Card
        style={{ minHeight: releasedHeight }}
        headStyle={{ padding: '0px 18px' }}
        bodyStyle={{ padding: '5px 24px' }}
        title={
          <Row justify="space-between" align="middle">
            <Typography.Title level={5} style={{ padding: 0, margin: 0, fontWeight: 600, fontSize: 20 }}>
              Shipment Emails
            </Typography.Title>
            <Row align="middle" gutter={6} style={{ margin: '0px 6px' }}>
              <Tooltip title="Are Shipment Notifications Enabled">
                <SwitchInputCard style={{ marginRight: 5 }} fieldName="notifications.shipmentNotifications.areShipmentNotificationsEnabled" label="Shipments Enabled" />
              </Tooltip>
            </Row>
          </Row>
        }>
        <Form layout="vertical" style={{ width: '100%' }}>
          <FormikProvider value={shipmentEmailFormik}>
            <Row>
              <Col span={24} style={{ marginTop: 12 }}>
                <EmailInput handleSubmit={handleAddRow} label="Email" type="email" onKeyDown={(event): void => (event.key === 'Enter' ? handleAddRow() : undefined)} fieldName="shipmentEmail" />
              </Col>
            </Row>
          </FormikProvider>
        </Form>
        <Divider />
        {!!values?.notifications?.shipmentNotifications.emailAddresses.length && (
          <List
            grid={{ column: 1 }}
            dataSource={values?.notifications?.shipmentNotifications.emailAddresses}
            renderItem={(item): React.ReactNode => (
              <Card style={{ width: '100%', borderRadius: 5, marginBottom: 5 }} bodyStyle={{ padding: '12px 18px' }}>
                <Row justify="space-between" align="middle">
                  <Col>
                    <MailOutlined style={{ fontSize: 16 }} />
                    <Typography.Text style={{ textAlign: 'center', marginLeft: 8, fontSize: 16 }}>{item}</Typography.Text>
                  </Col>
                  <Button
                    onClick={(): void => handleShipmentEmailDelete(item)}
                    icon={<MinusOutlined style={{ color: '#00000074', fontSize: 14 }} />}
                    style={{ border: 'none', background: '#0000001a', borderRadius: '50%', width: 24, height: 24 }}
                  />
                </Row>
              </Card>
            )}
          />
        )}
      </Card>
    </Col>
  );

  const releasedEmailJSX: JSX.Element = (
    <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} ref={releasedRef as Ref<HTMLDivElement>}>
      <Card
        style={{ minHeight: shipmentHeight }}
        headStyle={{ padding: '0px 18px' }}
        bodyStyle={{ padding: '5px 24px' }}
        title={
          <Row justify="space-between" align="middle">
            <Typography.Title level={5} style={{ padding: 0, margin: 0, fontWeight: 600, fontSize: 20 }}>
              Order Released Emails
            </Typography.Title>
            <Row align="middle" gutter={6} style={{ margin: '0px 6px' }}>
              <Tooltip title="Are Released Email Notifications Enabled">
                <SwitchInputCard style={{ marginRight: 5 }} fieldName="notifications.releaseNotifications.areReleasedNotificationsEnabled" label="Release Enabled" />
              </Tooltip>
            </Row>
          </Row>
        }>
        <Form layout="vertical" style={{ width: '100%' }}>
          <FormikProvider value={releaseEmailFormik}>
            <Row>
              <Col span={24} style={{ marginTop: 12 }}>
                <EmailInput
                  handleSubmit={handleAddReleaseRow}
                  label="Email"
                  type="email"
                  onKeyDown={(event): void => (event.key === 'Enter' ? handleAddReleaseRow() : undefined)}
                  fieldName="releaseEmail"
                />
              </Col>
            </Row>
          </FormikProvider>
        </Form>
        <Divider />
        {!!values.notifications?.releaseNotifications.emailAddresses.length && (
          <List
            style={{ marginBottom: 5 }}
            dataSource={values.notifications?.releaseNotifications.emailAddresses}
            renderItem={(item): React.ReactNode => (
              <Card style={{ width: '100%', borderRadius: 5, marginBottom: 5 }} bodyStyle={{ padding: '12px 18px' }}>
                <Row justify="space-between" align="middle">
                  <Col>
                    <MailOutlined style={{ fontSize: 16 }} />
                    <Typography.Text style={{ textAlign: 'center', marginLeft: 8, fontSize: 16 }}>{item}</Typography.Text>
                  </Col>
                  <Button
                    onClick={(): void => handleReleaseEmailDelete(item)}
                    icon={<MinusOutlined style={{ color: '#00000074', fontSize: 14 }} />}
                    style={{ border: 'none', background: '#0000001a', borderRadius: '50%', width: 24, height: 24 }}
                  />
                </Row>
              </Card>
            )}
          />
        )}
      </Card>
    </Col>
  );

  /* ******************** Renderer ******************** */
  return (
    <Col span={24} style={{ marginBottom: showNotifications ? 5 : 0 }}>
      <Row
        onClick={handleToggleShowNotifications}
        style={{ padding: 15, background: '#18a79984', borderRadius: 5, cursor: 'pointer', marginBottom: showNotifications ? 5 : 0 }}
        justify="space-between">
        <Typography.Title style={{ fontWeight: 400, padding: 0, margin: 0, fontSize: 22 }} level={3}>
          Notifications
        </Typography.Title>
        <Button style={{ width: 115 }} type="text" onClick={handleToggleShowNotifications} key="show-divisions" icon={<CaretSpinIcon isOpen={showNotifications} />}>
          {showNotifications ? 'Collapse' : 'Expand'}
        </Button>
      </Row>
      <animated.div style={{ ...{ minHeight: 10, overflow: 'hidden', width: '100%' }, ...expandAnimation }}>
        {showNotifications && (
          <div ref={ref as LegacyRef<HTMLDivElement>} style={{ width: '100%' }}>
            <Row gutter={[3, 3]}>
              {shipmentEmailJSX}
              {releasedEmailJSX}
            </Row>
          </div>
        )}
      </animated.div>
    </Col>
  );
};
