import { Form, InputNumber, InputNumberProps } from 'antd';
import { valueType } from 'antd/lib/statistic/utils';
import { useField } from 'formik';
import { FormLabel } from './FormLabel';

interface Props extends InputNumberProps {
  fieldName: string;
  label?: string;
}

export const CurrencyInput = ({ fieldName, label, ...rest }: Props): JSX.Element => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<valueType | null>(fieldName);

  return (
    <Form.Item
      labelAlign="left"
      validateStatus={touched && error ? 'error' : 'success'}
      label={label ? <FormLabel label={label} /> : undefined}
      help={touched && error ? error : undefined}
      style={{ marginBottom: 0, width: 220 }}
      labelCol={{ style: { padding: 0 } }}>
      <InputNumber
        {...rest}
        onKeyPress={(event): void => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        value={value || undefined}
        formatter={(value): string => `$ ${value}`}
        onChange={(value): void => setValue(value)}
        onFocus={(): void => setTouched(false)}
        onBlur={(): void => setTouched(true)}
        style={{ borderRadius: 5, width: 220 }}
      />
    </Form.Item>
  );
};
