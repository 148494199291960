import { Form, Select, SelectProps } from 'antd';
import { useField } from 'formik';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps {
  fieldName: string;
  label?: string;
}

export const SalesOrderTypeSelect = ({ fieldName, label, ...rest }: Props): JSX.Element => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<string>(fieldName);

  const handleChange = (value: string): void => {
    setValue(value);
  };

  const options = ['SO'].map((option) => ({ label: option, value: option }));

  return (
    <Form.Item
      labelAlign="left"
      validateStatus={touched && error ? 'error' : 'success'}
      label={label ? <FormLabel label={label} /> : undefined}
      help={touched && error ? error : undefined}
      style={{ marginBottom: 0 }}
      labelCol={{ style: { padding: 0 } }}>
      <Select allowClear onChange={handleChange} value={value} options={options} {...rest} onFocus={(): void => setTouched(false)} onBlur={(): void => setTouched(true)} style={{ borderRadius: 5 }} />
    </Form.Item>
  );
};
