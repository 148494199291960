import { Card, Form, Space, Typography } from 'antd';
import { SwitchInputCard } from 'components/UI/FormItems/SwitchInputCard';
import React from 'react';

export const ProjectsCard: React.FC = () => {
  /* ******************** Renderer ******************** */

  return (
    <Card
      style={{ height: '100%' }}
      title={
        <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
          Projects
        </Typography.Title>
      }
      bodyStyle={{ padding: 20 }}
      headStyle={{ background: '#b0b2bc' }}>
      <Form layout="vertical">
        <Space size={14} direction="vertical" style={{ width: '100%' }}>
          <SwitchInputCard fieldName="areProjectsEnabled" label="Are Projects Enabled" />
        </Space>
      </Form>
    </Card>
  );
};
