import { Form, Select, SelectProps } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { useField } from 'formik';
import { useGetModesOfDeliveryQuery } from 'redux/services/gladysTheCow/useGladysTheCowService';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps {
  fieldName: string;
  label?: string;
}

export const DeliveryModeTypeSelect = ({ fieldName, label, ...rest }: Props): JSX.Element => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<string>(fieldName);

  const { data, isLoading, isFetching } = useGetModesOfDeliveryQuery({ dataAreaId: 'MDSI', offset: 0 });

  const handleChange = (value: string): void => {
    setValue(value);
  };

  const options = data?.data.map((option) => ({ label: option.modeCode, value: option.modeCode }));

  if (isLoading) return <BlockLoader small direction="loader loader--slideUp" />;

  return (
    <Form.Item
      labelAlign="left"
      validateStatus={touched && error ? 'error' : 'success'}
      label={label ? <FormLabel label={label} /> : undefined}
      help={touched && error ? error : undefined}
      style={{ marginBottom: 0 }}
      labelCol={{ style: { padding: 0 } }}>
      <Select
        loading={isLoading || isFetching}
        onChange={handleChange}
        value={value}
        options={options}
        {...rest}
        onFocus={(): void => setTouched(false)}
        onBlur={(): void => setTouched(true)}
        style={{ borderRadius: 5 }}
      />
    </Form.Item>
  );
};
