import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Form, Select, SelectProps, Space } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { useField } from 'formik';
import { useMemo } from 'react';
import { useGetSitesQuery, useGetWarehousesQuery } from 'redux/services/kingLivingston/useKingstonLivingstonService';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps {
  siteFieldName: string;
  warehouseFieldName: string;
  siteLabel?: string;
  warehouseLabel?: string;
}

export const SiteSelectInput = ({ siteFieldName, warehouseFieldName, siteLabel, warehouseLabel, ...rest }: Props): JSX.Element => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<string>(siteFieldName);
  const [{ value: warehouseValue }, { touched: warehouseTouched, error: warehouseError }, { setValue: setWarehouseValue, setTouched: setWarehouseTouched }] = useField<string>(warehouseFieldName);

  const { data, isLoading, isFetching } = useGetSitesQuery({ dataAreaId: 'MDSI', offset: 0, overrideSkipTake: true });

  const {
    data: warehouseData,
    isLoading: isWarehouseLoading,
    isFetching: isWarehoueFetching
  } = useGetWarehousesQuery(value ? { site: value, offset: 0, dataAreaId: 'MDSI', overrideSkipTake: true } : skipToken);
  const warehouseOptions = useMemo(() => warehouseData?.data?.map((warehouse) => ({ label: warehouse.warehouseId, value: warehouse.warehouseId })), [warehouseData]);

  const options = data?.data.map((item) => ({ label: item.siteId, value: item.siteId }));

  const handleChange = (value: string): void => {
    setValue(value);
    setWarehouseValue(value);
  };
  const handleWarehouseChange = (value: string): void => {
    setWarehouseValue(value);
  };

  const handleClear = (): void => {
    setValue('');
    setWarehouseValue('');
  };

  if (isLoading || isWarehouseLoading) return <BlockLoader small direction="loader loader--slideUp" />;

  return (
    <Space direction="vertical">
      <Form.Item
        validateStatus={touched && error ? 'error' : 'success'}
        label={siteLabel ? <FormLabel label={siteLabel} /> : undefined}
        help={touched && error ? error : undefined}
        style={{ marginBottom: 0, width: 220 }}
        labelCol={{ style: { padding: 0 } }}>
        <Select
          onChange={handleChange}
          showSearch
          allowClear
          onClear={handleClear}
          value={value}
          loading={isLoading || isFetching}
          options={options}
          {...rest}
          onFocus={(): void => setTouched(false)}
          onBlur={(): void => setTouched(true)}
          style={{ borderRadius: 5 }}
        />
      </Form.Item>
      <Form.Item
        validateStatus={warehouseTouched && warehouseError ? 'error' : 'success'}
        label={warehouseLabel ? <FormLabel label={warehouseLabel} /> : undefined}
        help={warehouseTouched && warehouseError ? warehouseError : undefined}
        style={{ marginBottom: 0 }}
        labelCol={{ style: { padding: 0 } }}>
        <Select
          disabled
          onChange={handleWarehouseChange}
          showSearch
          allowClear
          value={value}
          loading={isWarehouseLoading || isWarehoueFetching}
          options={warehouseOptions}
          {...rest}
          onFocus={(): void => setWarehouseTouched(false)}
          onBlur={(): void => setWarehouseTouched(true)}
          style={{ borderRadius: 5 }}
        />
      </Form.Item>
    </Space>
  );
};
